<template>
  <div id="integration_content">
    <div class="integration_title">
      <label>Интеграция</label>
    </div>
    <div v-if="getComponent">
      <component :is="getComponent"></component>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { integrationMode } from "../config.js";

export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
  computed: {
    getComponent() {
      switch (integrationMode) {
        case "GROUND":
          return defineAsyncComponent(() =>
            import(`../components/IntegrationGround.vue`)
          );
      }
      return null;
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";
@import "../assets/styles/inputs.less";

#integration_content {
  margin-left: 20px;
  .integration_title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
  }
}
</style>
